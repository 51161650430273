import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
type Props = {
  videoTitle: string;
  isOpen: boolean;
  onClose: () => void;
  onChange: (e) => void;
  onSave: () => void;
  isPictorial?: boolean;
};
const ModalEditTitle = (props: Props) => {
  const {
    videoTitle,
    isOpen,
    onClose,
    onChange,
    onSave,
    isPictorial = false
  } = props;
  return <Dialog open={isOpen} onClose={onClose} maxWidth='sm' fullWidth>
            <DialogTitle>{tr(`front.video.modal.edit_title.title${isPictorial ? '_img' : ''}`, {}, 'front_office-video')}</DialogTitle>
            <DialogContent>
                <TextField autoFocus margin='dense' label={tr(`front.video.modal.edit_title.label`, {}, 'front_office-video')} type='text' fullWidth name='name' variant='standard' onChange={e => onChange(e)} value={videoTitle} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{tr(`front.video.modal.edit_title.buttons.cancel`, {}, 'front_office-video')}</Button>
                <Button onClick={onSave}>{tr(`front.video.modal.edit_title.buttons.save`, {}, 'front_office-video')}</Button>
            </DialogActions>
        </Dialog>;
};
export default ModalEditTitle;