import React from 'react';
import { Network } from '@components/office/front-office/organization/social/types/Network';
import { faFacebook as FacebookIcon } from '@fortawesome/free-brands-svg-icons';
import { HumanRatioEnum } from '@components/office/front-office/organization/social/enums/HumanRatioEnum';
const FacebookConfiguration = (): Network => {
  return {
    name: 'facebook',
    title: 'Facebook',
    subtitleFieldName: 'actualPage',
    icon: FacebookIcon,
    color: '#3b5998',
    rules: {
      video: {
        types: {
          post: {
            title: {
              required: false
            },
            description: {
              required: false
            },
            availableHumanRatio: [HumanRatioEnum.SQUARE, HumanRatioEnum.LANDSCAPE, HumanRatioEnum.PORTRAIT]
          },
          reel: {
            title: {
              required: false
            },
            description: {
              required: false
            },
            availableHumanRatio: [HumanRatioEnum.PORTRAIT]
          },
          story: {
            title: {
              required: false
            },
            description: {
              required: false
            },
            availableHumanRatio: [HumanRatioEnum.PORTRAIT]
          },
          short: false
        },
        defaultType: 'post',
        durations: {
          post: {
            min: 0,
            max: 0
          },
          reel: {
            min: 0,
            max: 90
          },
          story: {
            min: 0,
            max: 60
          },
          short: {
            min: 0,
            max: 0
          }
        }
      },
      picture: {
        types: {
          post: {
            title: {
              required: false
            },
            description: {
              required: false
            },
            availableHumanRatio: [HumanRatioEnum.SQUARE, HumanRatioEnum.LANDSCAPE, HumanRatioEnum.PORTRAIT]
          },
          reel: false,
          story: {
            title: {
              required: false
            },
            description: {
              required: false
            },
            availableHumanRatio: [HumanRatioEnum.SQUARE, HumanRatioEnum.LANDSCAPE, HumanRatioEnum.PORTRAIT]
          },
          short: false
        },
        defaultType: 'post'
      }
    },
    enabled: true,
    editorOptions: {
      toolbar: ['emoticons']
    },
    explicativeText: <div className='mr-4 text-right'>
                {tr?.(`front.social_page.facebook.note_explicative`)}
                <a href='https://help.danim.com/fr/articles/9240417-ajouter-un-compte-facebook-sur-danim' target='_blank' rel='noreferrer' className='ml-1'>
                    {tr?.(`front.social_page.facebook.link_label`)}
                </a>
            </div>
  };
};
export default FacebookConfiguration;