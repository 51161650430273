import React, { useCallback, useMemo } from 'react';
import LoadingVideo from '@components/office/front-office/video/ratio-variation-panel/LoadingVideo';
import { Daughter } from '@components/office/front-office/organization/social/types/Daughter';
import VideoFormatShow from '@components/office/front-office/video/ratio-variation-panel/video-format-show/VideoFormatShow';
import StudioMiscellaneous from '@components/bridge/video/StudioMiscellaneous';
type Props = {
  isLoadingVideo: boolean;
  video: any;
  appuser: any;
  workspaceID: string;
  mutateVideo: any;
};
const RatioVariationPanel = (props: Props) => {
  const {
    isLoadingVideo,
    video,
    appuser,
    workspaceID,
    mutateVideo
  } = props;
  const getRatio = (resolution: [number, number]): number => {
    return parseFloat((resolution[0] / resolution[1]).toFixed(2));
  };
  const resolutions: [number, number][] = useMemo(() => {
    const config = StudioMiscellaneous.templateConfig(video?.template);
    const resultat = config?.info?.resolutions || [];
    return resultat.filter(resolution => video.ratio !== getRatio(resolution));
  }, [video]);
  const getDaughterByHumanRatio = useCallback((ratio: number): Daughter => {
    return video?.daughters?.edges?.find(d => d.node.ratio === ratio) ? video?.daughters?.edges?.find(d => d.node.ratio === ratio)?.node : null;
  }, [video]);
  const getWorkspace = useCallback(() => {
    return appuser.organization.workspaces.edges.find(uniqueWorkspace => uniqueWorkspace.node.id === workspaceID) || null;
  }, [appuser]);
  if (resolutions.length === 0) return <></>;
  return <div className='-item'>
            <h3 className='-title'>{tr(`front.snippet.formats.title`, {}, 'front_office-video')}</h3>
            <p className='-subTitle'>{tr(`front.snippet.formats.subtitle`, {}, 'front_office-video')}</p>
            {isLoadingVideo ? <LoadingVideo /> : <div className='-formats'>
                    {resolutions.map(resolution => {
        const ratioValue = getRatio(resolution);
        const ratioCode = StudioMiscellaneous.getRatioCode(resolution, getRatio(resolution));
        const ratioLabel = StudioMiscellaneous.getRatioLabel(resolution.join('x'), ratioValue);
        const ratioExempleLabel = StudioMiscellaneous.getRatioExampleLabel(ratioCode);
        return <VideoFormatShow key={ratioValue} title={ratioLabel} video={video} appuser={appuser} workspace={getWorkspace()} format={ratioCode} daughter={getDaughterByHumanRatio(ratioValue)} mutateVideo={mutateVideo} helpMessage={ratioExempleLabel} />;
      })}
                </div>}
        </div>;
};
export default RatioVariationPanel;