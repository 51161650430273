import React, { useCallback, useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
type Props = {
  isPictorial: boolean;
  onAction: (value: any) => void;
};
const DaughterMenuActions = (props: Props) => {
  const {
    onAction,
    isPictorial
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const options = useMemo(() => {
    const actions: any[] = [];
    actions.push({
      label: tr(`front.snippet.formats.buttons.more.edit`, {}, 'front_office-video'),
      value: 'edit'
    });
    actions.push({
      label: tr(`front.snippet.formats.buttons.more.download`, {}, 'front_office-video'),
      value: 'download'
    });
    if (!isPictorial) {
      actions.push({
        label: tr(`front.snippet.formats.buttons.more.thumbnail`, {}, 'front_office-video'),
        value: 'thumbnail'
      });
    }
    return actions;
  }, [isPictorial]);
  const handleAction = useCallback((value: string) => {
    setAnchorEl(null);
    onAction(value);
  }, [onAction]);
  return <div>
            <IconButton aria-label='more' id='long-button' aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup='true' onClick={handleOpenMenu}>
                <MoreVertIcon />
            </IconButton>
            <Menu id='long-menu' MenuListProps={{
      'aria-labelledby': 'long-button'
    }} anchorEl={anchorEl} open={open} onClose={handleCloseMenu} PaperProps={{
      style: {
        maxHeight: 48 * 4.5,
        width: '25ch'
      }
    }} style={{
      border: '1px solid red'
    }}>
                {options.map(option => <MenuItem key={option.value} onClick={() => handleAction(option.value)}>
                        {option.label}
                    </MenuItem>)}
            </Menu>
        </div>;
};
export default DaughterMenuActions;