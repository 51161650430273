import React from 'react';
import TooltipBox from '@components/bridge/bridge/TooltipBox';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
const LoadingVideo = () => {
  return <div className='-loading'>
            <TooltipBox caption={tr(`front.snippet.formats.loading`, {}, 'front_office-video')} style={{
      bottom: 10,
      right: 0,
      transform: 'translateY(-50%)'
    }}>
                <HourglassBottomOutlinedIcon sx={{
        color: '#000',
        animation: 'spin 2s linear infinite',
        '@keyframes spin': {
          '0%': {
            transform: 'rotate(360deg)'
          },
          '100%': {
            transform: 'rotate(0deg)'
          }
        }
      }} width={24} />
            </TooltipBox>
        </div>;
};
export default LoadingVideo;