import React from 'react';
import { Network } from '@components/office/front-office/organization/social/types/Network';
import { RulesDurations } from '@components/office/front-office/organization/social/types/RulesDurations';
import FacebookConfiguration from '@components/office/front-office/organization/social/networks/FacebookConfiguration';
import InstagramConfiguration from '@components/office/front-office/organization/social/networks/InstagramConfiguration';
import LinkedinConfiguration from '@components/office/front-office/organization/social/networks/LinkedinConfiguration';
import YoutubeConfiguration from '@components/office/front-office/organization/social/networks/YoutubeConfiguration';
import TikTokConfiguration from '@components/office/front-office/organization/social/networks/TikTokConfiguration';
import { SocialConnections } from '@components/office/front-office/organization/social/types/SocialConnections';
import { Options } from '@components/office/front-office/organization/social/types/Options';
import { NetworkRulesTypes } from '@components/office/front-office/organization/social/types/NetworkRulesTypes';
import { HumanRatio } from '@components/office/front-office/organization/social/types/HumanRatio';
import { HumanRatioEnum } from '@components/office/front-office/organization/social/enums/HumanRatioEnum';
const getConfiguration = (): Network[] => [FacebookConfiguration(), InstagramConfiguration(), LinkedinConfiguration(), YoutubeConfiguration(), TikTokConfiguration()];
const getConfigurationByTitle = (title: string): Network | undefined => {
  return getConfiguration().find(n => n.title.toLowerCase() === title?.toLowerCase()) || undefined;
};
const getRulesTypes = (title: string, isPictorial: boolean = false): NetworkRulesTypes => {
  const configuration = getConfigurationByTitle(title);
  if (isPictorial) {
    return configuration?.rules.picture.types;
  }
  return configuration?.rules.video.types;
};
const getNetworkDuration = (title: string, isPictorial: boolean = false): {
  post: RulesDurations;
  reel: RulesDurations;
  story: RulesDurations;
  short: RulesDurations;
} => {
  const configuration = getConfigurationByTitle(title);
  if (isPictorial) {
    return null;
  }
  return configuration?.rules.video.durations;
};
const getDefaultType = (title: string, isPictorial: boolean = false): string => {
  const configuration = getConfigurationByTitle(title);
  if (isPictorial) {
    return configuration?.rules.picture.defaultType;
  }
  return configuration?.rules.video.defaultType;
};
const getOptions = (network: Network, socialNetworks: SocialConnections): Options => {
  const socialNetwork = socialNetworks?.[network.name];
  const networkId = network.name === 'instagram' ? socialNetwork?.facebookUserId : socialNetwork?.userId;
  const indexKey = networkId && network?.title ? `${networkId}-${network?.title}` : null;
  const networksLabel = {
    facebook: socialNetwork?.actualPage?.name || network?.title,
    instagram: socialNetwork?.actualBusinessAccount?.name || socialNetwork?.facebookUser?.name || network?.title,
    linkedin: socialNetwork?.actualOrganizationPage?.name || socialNetwork?.user?.name || network?.title,
    youtube: socialNetwork?.actualChannel?.name || socialNetwork?.user?.name || network?.title,
    tiktok: socialNetwork?.user?.name || network?.title
  };
  return {
    id: networkId,
    network: network?.title,
    label: networksLabel[network.name] || null,
    icon: network?.icon,
    color: network?.color,
    rules: network?.rules,
    enabled: socialNetwork?.enabled && socialNetwork?.ready,
    indexKey
  };
};
const getIsPictorial = (video): boolean => {
  return Boolean(video?.pictorial);
};
const getAvailableHumanRatio = (video, rulesAvailableFormats): HumanRatio[] => {
  return [HumanRatioEnum.SQUARE, HumanRatioEnum.LANDSCAPE, HumanRatioEnum.PORTRAIT].filter((format: HumanRatio) => {
    return rulesAvailableFormats?.includes(format) && (video?.daughters?.edges?.find(daughter => daughter.node?.humanRatio === format && daughter.node?.progress?.percentage === 100) || video?.humanRatio === format);
  });
};
export default {
  getConfiguration,
  getConfigurationByTitle,
  getRulesTypes,
  getNetworkDuration,
  getDefaultType,
  getOptions,
  getIsPictorial,
  getAvailableHumanRatio
};