import { Network } from '@components/office/front-office/organization/social/types/Network';
import { faYoutube as YoutubeIcon } from '@fortawesome/free-brands-svg-icons';
import { HumanRatioEnum } from '@components/office/front-office/organization/social/enums/HumanRatioEnum';
const YoutubeConfiguration = (): Network => {
  return {
    name: 'youtube',
    title: 'Youtube',
    subtitleFieldName: 'actualChannel',
    icon: YoutubeIcon,
    color: '#FF0000',
    rules: {
      video: {
        types: {
          post: {
            title: {
              required: true
            },
            description: {
              required: true
            },
            availableHumanRatio: [HumanRatioEnum.SQUARE, HumanRatioEnum.LANDSCAPE, HumanRatioEnum.PORTRAIT]
          },
          reel: false,
          story: false,
          short: {
            title: {
              required: true
            },
            description: {
              required: true
            },
            availableHumanRatio: [HumanRatioEnum.SQUARE, HumanRatioEnum.LANDSCAPE, HumanRatioEnum.PORTRAIT]
          }
        },
        defaultType: 'short',
        durations: {
          post: {
            min: 0,
            max: 0
          },
          reel: {
            min: 0,
            max: 0
          },
          story: {
            min: 0,
            max: 0
          },
          short: {
            min: 0,
            max: 60
          }
        }
      },
      picture: {
        types: {
          post: false,
          reel: false,
          story: false,
          short: false
        },
        defaultType: null
      }
    },
    enabled: true,
    editorOptions: {
      toolbar: ['emoticons']
    }
  };
};
export default YoutubeConfiguration;