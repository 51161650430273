import React from 'react';
import __Base from '@components/Base';
import _ from 'lodash';
import TooltipBox from '@components/bridge/bridge/TooltipBox';
import { faCloud, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CreditsBar from '@components/bridge/bridge/CreditsBar';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import applyDeep from '@utils/lodash/applyDeep';
import { apiRequest, axios } from '@services/http/client';
import { mutate } from 'swr';
import queryFrontVideoShowApi from '@queries/api/office/front-office/video/queryVideoShow';
import * as Sentry from '@sentry/nextjs';
import _axios from 'axios';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import { formatDistance } from 'date-fns';
import { getFnsLocaleFromI18nLocale } from '@hooks/useCustomTranslation';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { Alert, Button } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import VideoFile from '@components/bridge/bridge/VideoFile';
import ModalImageInFullscreen from '@components/office/front-office/video/ModalImageInFullscreen';
import Workspace from '@utils/Workspace';
import Networks from '@components/office/front-office/organization/social/Networks';
import RatioVariationPanel from '@components/office/front-office/video/ratio-variation-panel/RatioVariationPanel';
import ModalThumbnail from '@components/office/front-office/video/ratio-variation-panel/video-format-show/ModalThumbnail';
import ShareHistoryPanel from '@components/office/front-office/video/share-history-panel/ShareHistoryPanel';
import ModalEditTitle from './ModalEditTitle';
import ModalEditNote from './ModalEditNote';
import i18n from '../../../../../i18n';
class ItemPage extends __Base {
  constructor(props) {
    super(props);
    this.REFRESH_TIMEOUT = 1000; // in ms

    this.state = {}; // see getDerivedStateFromProps
  }
  static getDerivedStateFromProps = (nextProps, prevState) => {
    return {
      // mirroring, see https://stackoverflow.com/a/50080417/6503789
      ...(nextProps.video !== _.get(prevState, '_video') && {
        _video: nextProps.video
      }),
      // derivation
      ...(nextProps.video !== _.get(prevState, '_video') && {
        forms: {
          snippet: {
            name: {
              value: _.get(nextProps, 'video.name') || '',
              changed: false,
              error: null
            },
            description: {
              value: _.get(nextProps, 'video.description') || '',
              changed: false,
              error: null
            },
            submits: {
              update: {
                waiting: false
              },
              reinitialize: {
                waiting: false
              },
              backToDraft: {
                waiting: false
              },
              remove: {
                waiting: false
              }
            }
          },
          generateWithoutWatermark: {
            waiting: false
          }
        },
        openModalEditNote: false,
        openModalEditTitle: false,
        openModalThumbnail: false,
        openModalImageInFullscreen: false,
        isPictorial: !!_.get(nextProps, 'video.template.pictorial'),
        state: _.get(nextProps, 'video.state') || null,
        preview: {
          playing: false,
          mute: true
        }
      })
    };
  };
  componentDidMount = () => {
    if (!this.isDone()) {
      this.refreshTimeout = setTimeout(this.refresh, this.REFRESH_TIMEOUT);
    }
    this._isMounted = true;
  };
  componentDidUpdate = prevProps => {
    if (prevProps.video !== this.props.video) {
      if (!this.isDone()) {
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, this.REFRESH_TIMEOUT);
      }
    }
  };
  componentWillUnmount = () => {
    this._isMounted = false;
    clearTimeout(this.refreshTimeout);
  };
  handleBackToDraftClick = async () => {
    const {
      video,
      router
    } = this.props;
    let body = {
      videoId: video.id,
      botServerId: _.get(video, 'bot.materials.serverId'),
      patch: {
        state: 'DRAFT'
      }
    };
    this.updateState(draft => {
      draft.forms.snippet.submits.backToDraft.waiting = true;
    });
    const underWhitelabel = !window.areWeUnderDanimHost();
    const options = {
      method: 'POST',
      url: gen('write/video/change'),
      data: body,
      underWhitelabel
    };
    await axios(options, {
      then: async () => {
        try {
          await waitForAggregate({
            id: _.get(video, 'id'),
            domain: 'video'
          });
          mutateVideo();
          router.push(`/video/edit/${video.shortId}`);
        } catch (e) {
          this.updateState(draft => {
            draft.forms.snippet.submits.backToDraft.waiting = false;
          });
        }
      },
      catch: () => {
        this.updateState(draft => {
          draft.forms.snippet.submits.backToDraft.waiting = false;
        });
        addFlash({
          type: 'warning',
          message: tr('front.item_page.flash.back_to_draft.error', {}, 'front_office-video')
        });
      }
    });
  };
  handleReinitializeClick = async () => {
    const {
      video
    } = this.props;
    let body = {
      videoId: video.id,
      draft: true
    };
    this.updateState(draft => {
      draft.forms.snippet.submits.reinitialize.waiting = true;
    });
    const underWhitelabel = !window.areWeUnderDanimHost();
    const options = {
      method: 'POST',
      url: gen('write/video/reinitialize'),
      data: body,
      underWhitelabel
    };
    await axios(options, {
      catch: () => {
        addFlash({
          type: 'warning',
          message: tr('front.item_page.flash.reinitialize.error', {}, 'front_office-video')
        });
      },
      finally: () => {
        this.updateState(draft => {
          draft.forms.snippet.submits.reinitialize.waiting = false;
        });
      }
    });
  };
  handleRemoveClick = () => {
    addFlash({
      type: null,
      message: tr(`front.item_page.flash.confirm.video_removal.question${isPictorial ? '_img' : ''}`, {}, 'front_office-video'),
      goText: tr('front.item_page.flash.confirm.video_removal.yes', {}, 'front_office-video'),
      goCallback: this.remove
    }, 10000);
  };
  handleShareClick = () => {
    const {
      isPictorial
    } = this.state;
    copyToClipboard(isPictorial ? `${this.props.video.thumbnail.src}` : `${window.playerLink(this.props.video.shortId)}?loop=1&autoplay=0`);
    addFlash({
      type: 'success',
      message: tr('front.flash.copied.link', {}, 'bridge-bridge')
    });
  };
  handleSnippetInputChange = ev => {
    let target = ev.target;
    let value = _.get(target, 'type') === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    this.updateState(draft => {
      if (draft.forms.snippet[name].value !== value) {
        draft.forms.snippet[name].value = value;
        draft.forms.snippet[name].changed = true;
      }
    });
  };
  handleUpdateClick = async () => {
    const {
      video,
      mutateVideo
    } = this.props;
    if (this.validateSnippetForm()) {
      let body = {
        videoId: video.id,
        patch: {
          ...(_.get(this.state, 'forms.snippet.name.changed') && {
            name: this.state.forms.snippet.name.value
          }),
          ...(_.get(this.state, 'forms.snippet.description.changed') && {
            description: this.state.forms.snippet.description.value
          })
        }
      };
      this.updateState(draft => {
        draft.forms.snippet.submits.update.waiting = true;
      });
      if (Object.keys(body.patch).length) {
        const underWhitelabel = !window.areWeUnderDanimHost();
        const options = {
          method: 'POST',
          url: gen('write/video/change'),
          data: body,
          underWhitelabel
        };
        await axios(options, {
          then: () => {
            this.initSnippetForm();
            addFlash({
              type: 'success',
              message: tr('front.item_page.flash.change_video.success', {}, 'front_office-video')
            });
          },
          catch: () => {
            addFlash({
              type: 'warning',
              message: tr('front.item_page.flash.change_video.error', {}, 'front_office-video')
            });
          },
          finally: () => {
            this.updateState(draft => {
              draft.forms.snippet.submits.update.waiting = false;
            });
            mutateVideo();
          }
        });
      } else {
        this.updateState(draft => {
          draft.forms.snippet.submits.update.waiting = false;
        });
        addFlash({
          type: 'success',
          message: tr('front.item_page.flash.change_video.success', {}, 'front_office-video')
        });
      }
    }
  };
  handleDownloadClick = ev => {
    ev.preventDefault();
    const {
      isPictorial
    } = this.state;
    // We can't just use the download attribute because not same-origin, get the blob with rename.
    const {
      video
    } = this.props;
    const fileSrc = isPictorial ? _.get(video, 'thumbnail.src', '') : _.get(video, 'file.src', '');
    const fileName = _.get(video, 'name') || _.get(video, 'template.name', '');
    addFlash({
      id: 'download',
      type: 'info',
      message: tr('front.video.flash.download'),
      closable: false,
      autoClose: false
    });
    _axios({
      url: fileSrc,
      method: 'GET',
      responseType: 'blob' // important
    }).then(response => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${fileName}.${isPictorial ? 'jpg' : 'mp4'}`);
      document.body.appendChild(link);
      link.click();
      updateFlash('download', {
        type: 'success',
        render: tr('front.video.flash.downloaded'),
        closable: true,
        autoClose: true
      });
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }).catch(error => {
      dismissFlash();
      addFlash({
        type: 'warning',
        message: tr('front.flash.error')
      });
      Sentry.captureException(error);
    });
  };
  initSnippetForm = () => {
    this.updateState(draft => {
      draft.forms.snippet = applyDeep(draft.forms.snippet, 'changed', value => false);
    });
  };
  isDone = () => _.get(this.props, 'video.state') === 'DONE' || _.get(this.state, 'state') === 'DONE';
  refresh = () => {
    if (!this._isMounted || this.isDone()) return;
    const {
      video
    } = this.props;
    let queryString = `
        { 
            video (id: "${video.id}") {
                id
                shortId
                state
            }
        }
    `;
    apiRequest(queryString).then(async data => {
      if (!data) {
        await Sentry.captureException(new Error({
          message: 'No data from apiRequest in VideoItemPage.refresh()',
          video
        }));
      }
      if (data?.data?.video) {
        if (data.data.video.state === 'DONE') {
          await mutate(queryFrontVideoShowApi(video.shortId));
          clearTimeout(this.refreshTimeout);
          this.updateState(draft => {
            draft.state = data.data.video.state;
          });
          if (window.mode !== 'demo') {
            addFlash({
              type: 'success',
              message: tr('front.item_page.flash.finished', {}, 'front_office-video')
            });

            // this.refreshDone is here to avoid refresh repetitions (since ajax with state=DONE could potentially cause it)
            if (this.refreshDone === undefined) this.refreshDone = {};
            if (!this.refreshDone[video.id]) {
              this.refreshDone[video.id] = true;
              // this.props.router.reload();
              setTimeout(() => delete this.refreshDone[video.id], 2 * this.REFRESH_TIMEOUT); // we could need to have !this.refreshDone[video.id]=true if we pass the video from watermarked to real
            }
          }
        }
        if (data.data.video.state !== 'DONE') this.refreshTimeout = setTimeout(this.refresh, this.REFRESH_TIMEOUT);
      } else {
        addFlash({
          type: 'error',
          message: tr('front.item_page.flash.video.error.null'),
          goText: tr('front.item_page.flash.video.error.actions.help'),
          goCallback: () => {
            window.open('https://help.danim.com', '_blank');
          }
        });
        this.props.router.push('/');
      }
    }).catch(error => {
      Sentry.captureException(error);
    });
  };
  remove = async () => {
    const {
      video,
      router
    } = this.props;
    let body = {
      videoId: video.id,
      ignoreNonExisting: true
    };
    this.updateState(draft => {
      draft.forms.snippet.submits.remove.waiting = true;
    });
    const underWhitelabel = !window.areWeUnderDanimHost();
    const options = {
      method: 'POST',
      url: gen('write/video/remove'),
      data: body,
      underWhitelabel
    };
    await axios(options, {
      then: () => {
        addFlash({
          type: 'success',
          message: tr('front.item_page.flash.remove.video.success', {}, 'front_office-video')
        });
        router.push('/video/library');
      },
      catch: () => {
        addFlash({
          type: 'warning',
          message: tr('front.item_page.flash.remove.video.error', {}, 'front_office-video')
        });
      },
      finally: () => {
        this.updateState(draft => {
          draft.forms.snippet.submits.remove.waiting = false;
        });
      }
    });
  };

  /**
   * Returns bool: true if no validation error was detected
   */
  validateSnippetForm = () => {
    let noProblem = true;
    let filled = {};

    // filled
    //      name
    filled.name = !!this.state.forms.snippet.name.value;
    this.updateState(draft => {
      draft.forms.snippet.name.error = filled.name ? null : ['front.item_page.forms.snippet.name.error.empty', {}, 'front_office-video'];
    });
    noProblem *= filled.name;
    return noProblem;
  };

  // The modulo operation % 4 ensures the sum is reduced to a number between 0 and 3
  // and adding 1 shifts this range to 1-4.
  selectNumberFromString = str => {
    let sum = 0;
    for (let i = 0; i < str.length; i++) {
      sum += str.charCodeAt(i);
    }
    return sum % 4 + 1;
  };
  render = () => {
    const {
      video,
      mutateVideo,
      appuser,
      router,
      isLoadingVideo,
      socialPosts,
      mutateSocialPosts,
      isLoadingSocialPosts,
      isValidatingSocialPosts
    } = this.props;
    const {
      isPictorial
    } = this.state;
    const randomNumberForWaitingGif = this.selectNumberFromString(video?.id ?? 1);
    let ratio = Math.max(1, parseFloat(_.get(video, 'ratio') || 1));
    let creatorNames = _.get(video, 'creator.profile.names.both', null);
    const templateData = JSON.parse(_.get(video, 'templateData') || '{}');
    const templateConfig = video.template ? JSON.parse(_.get(video, 'template.config') || '{}') : null;
    const templateState = video?.template?.state ?? null;
    const themeIdx = _.get(templateData, 'info.theme.variant_index') ?? _.get(templateConfig, 'info.theme.variant_index') ?? 0;
    const ratioIdx = _.get(templateData, 'info.ratio_index') ?? _.get(templateConfig, 'info.ratio_index') ?? 0;
    // responsive video
    let maxHeight = 16 * 20; // 20rem
    let maxWidth = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 16 * (20 + 5 * 2); // window - snippet - paddings and margins
    let width = null;
    let height = null;
    let style = null;
    if (this.isDone()) {
      if (ratio) {
        // responsive
        if (maxHeight * ratio > maxWidth) {
          width = maxWidth;
          height = width / ratio;
        } else {
          height = maxHeight;
          width = height * ratio;
        }
        if (!isPictorial) style = {
          width,
          height
        };else style = {
          width
        };
      } else {
        if (!isPictorial) style = style = {
          maxHeight,
          maxWidth
        };else style = {
          maxWidth
        };
      }
    }
    style = {
      ...style,
      display: 'inline-table'
    };
    const title = <div className='-block'>
                <h1 className='-title'>{video.minName || video.name}</h1>
                <div className='-action'>
                    <div className='-item'>
                        <TooltipBox caption={tr(`front.video.tooltip.edit_title`, {}, 'front_office-video')} style={{
            left: 'calc(100% + .4rem)',
            top: '50%',
            transform: 'translateY(-50%)',
            fontWeight: 'initial',
            fontSize: 'initial'
          }}>
                            <ModeEditIcon onClick={() => this.setState({
              openModalEditTitle: true
            })} style={{
              cursor: 'pointer',
              fontWeight: 'initial',
              fontSize: 'initial'
            }} width={16} />
                        </TooltipBox>
                    </div>
                    <div className='-item'>
                        <TooltipBox caption={tr(`front.snippet.render.${video.generatedInCloud ? 'cloud' : 'local'}`, {}, 'front_office-video')} style={{
            left: 'calc(100% + .4rem)',
            top: '50%',
            transform: 'translateY(-50%)',
            fontWeight: 'initial',
            fontSize: 'initial'
          }}>
                            <FontAwesomeIcon icon={video.generatedInCloud ? faCloud : faHome} width={16} />
                        </TooltipBox>
                    </div>
                </div>
            </div>;
    const isThereALocalBotForRender = !!_.get(appuser, 'organization.botIds.length');
    const beingMade = video.generatedInCloud || isThereALocalBotForRender;
    const workspaceID = Workspace.getSelectedWorkspaceId();
    const handleSaveThumbnail = async (file, capturedAt) => {
      const underWhitelabel = !window.areWeUnderDanimHost();
      const body = {
        videoId: video.id,
        humanRatio: video.humanRatio,
        thumbnailTime: capturedAt || null,
        thumbnail: file || null
      };
      let formData = new FormData();
      body.videoId !== null && formData.append('videoId', body.videoId);
      body.humanRatio !== null && formData.append('humanRatio', body.humanRatio);
      body.thumbnailTime !== null && formData.append('patch[thumbnailTime]', body.thumbnailTime);
      body.thumbnail !== null && formData.append('patch[thumbnail]', body.thumbnail);
      const options = {
        method: 'POST',
        url: gen(`write/video/change-social-post-settings`),
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        underWhitelabel
      };
      await axios(options, {
        then: () => {
          addFlash({
            type: 'success',
            message: tr('front.item_page.core.share.modal_thumbnail.success', {}, 'front_office-video')
          });
        },
        catch: e => {
          addFlash({
            type: 'warning',
            message: tr('front.item_page.core.share.modal_thumbnail.error', {}, 'front_office-video')
          });
          console.log(e);
        },
        finally: () => {
          this.setState({
            openModalThumbnail: false
          });
          mutateVideo();
        }
      });
    };
    const isNetworkShareButtonVisible = () => {
      return Networks.getIsPictorial(video) ? video.template !== null && video.thumbnail !== null && video.thumbnail?.src !== null : video.template !== null && video.file !== null && video.file?.src !== null;
    };
    return <main className={clsx('video:itemPage')}>
                {this.state.openModalImageInFullscreen && <ModalImageInFullscreen title={video.minName || video.name} isOpen={this.state.openModalImageInFullscreen} onClose={() => this.setState({
        openModalImageInFullscreen: false
      })} imagePath={video.thumbnail?.src} />}
                {this.state.openModalEditTitle && <ModalEditTitle isOpen={this.state.openModalEditTitle} onClose={() => this.setState({
        openModalEditTitle: false
      })} onChange={e => this.handleSnippetInputChange(e)} onSave={() => {
        this.setState({
          openModalEditTitle: false
        });
        this.handleUpdateClick();
      }} videoTitle={this.state.forms.snippet.name.value} isPictorial={isPictorial} />}
                {this.state.openModalEditNote && <ModalEditNote isOpen={this.state.openModalEditNote} onClose={() => this.setState({
        openModalEditNote: false
      })} onChange={e => this.handleSnippetInputChange(e)} onSave={() => {
        this.setState({
          openModalEditNote: false
        });
        this.handleUpdateClick();
      }} videoNote={this.state.forms.snippet.description.value} isPictorial={isPictorial} />}
                {this.state.openModalThumbnail && <ModalThumbnail videoUrl={video?.file?.src} thumbnail={video?.socialPostSettings?.thumbnail} thumbnailTime={video?.socialPostSettings?.thumbnailTime} isOpen={this.state.openModalThumbnail} onClose={() => this.setState({
        openModalThumbnail: false
      })} onSave={handleSaveThumbnail} />}
                <CreditsBar customAttributes={{
        root: {
          className: '-creditsBar'
        }
      }} title={title} />
                <div className='-full'>
                    <div className='-core'>
                        <div className='-video'>
                            {(!this.isDone() || !appuser) === true && <React.Fragment>
                                    <div className='-title' data-test-id='video-in-progress'>
                                        {beingMade ? <>
                                                {tr(`front.item_page.core.video.video_is.being_made_1${isPictorial ? '_img' : ''}`, {}, 'front_office-video')}
                                                <br />
                                                {tr(`front.item_page.core.video.video_is.being_made_2`, {}, 'front_office-video')}
                                            </> : this.isDone() ? tr(`front.item_page.core.video.video_is.done${isPictorial ? '_img' : ''}'}`, {}, 'front_office-video') : !beingMade && tr(`front.item_page.core.video.video_is.waiting${isPictorial ? '_img' : ''}`, {}, 'front_office-video')}
                                    </div>
                                    <img src={`/img/gif/waiting_${randomNumberForWaitingGif}.gif`} alt='waiting' style={{
                width: '100%',
                maxWidth: '450px'
              }} />
                                </React.Fragment>}
                            {!!(this.isDone() && appuser?.activated()) === true && <React.Fragment>
                                    {creatorNames !== null && <div className='-creator'>
                                            <div className='-name' dangerouslySetInnerHTML={{
                  __html: tr('front.item_page.forms.snippet.created_by', {
                    names: creatorNames,
                    medium: video.generatedInCloud ? 'Danim Cloud' : 'Danim Creator'
                  }, 'front_office-video')
                }} />
                                            <div className='-date'>
                                                {formatDistance(new Date(video.createdAt), new Date(), {
                    addSuffix: true,
                    locale: getFnsLocaleFromI18nLocale(i18n.defaultLang)
                  })}
                                            </div>
                                        </div>}
                                    {!isLoadingVideo ? <div style={style}>
                                            <VideoFile mute={this.state.preview.mute} loop customAttributes={{
                  root: {
                    className: '-preview'
                  }
                }} videoOnlyWhenNeeded={false} src={video?.file?.src || _.get(templateConfig, `info._previews.${ratioIdx || 0}.${themeIdx || 0}.src`) || _.get(templateConfig, `info._previews.0.${themeIdx || 0}.src`) || _.get(templateConfig, `info._previews.${ratioIdx || 0}.0.src`) || _.get(templateConfig, `info._previews.0.0.src`) || _.get(video, 'template.preview.src')} poster={video?.socialPostSettings?.thumbnail?.src || video?.thumbnail?.src || _.get(templateConfig, `info._thumbnails.${ratioIdx || 0}.${themeIdx || 0}.src`) || _.get(templateConfig, `info._thumbnails.0.${themeIdx || 0}.src`) || _.get(templateConfig, `info._thumbnails.${ratioIdx || 0}.0.src`) || _.get(templateConfig, `info._thumbnails.0.0.src`) || _.get(video, 'template.thumbnail.src')} playing={this.state.preview.playing} controls thrifty={false} isPictorial={isPictorial} />
                                        </div> : <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%'
              }}>
                                            <img src={`/img/gif/waiting_${randomNumberForWaitingGif}.gif`} alt='waiting' style={{
                  width: '100%',
                  maxWidth: '450px'
                }} />
                                        </div>}
                                </React.Fragment>}
                            <div className='-infos'>
                                {templateState === 'DRAFT' && <Alert icon={false} severity='error' sx={{
                m: '1rem',
                textAlign: 'center'
              }}>
                                        {tr(`front.item_page.forms.snippet.template_is_draft${isPictorial ? '_img' : ''}`, {}, 'front_office-video')}
                                    </Alert>}
                            </div>
                            <div className='-note'>
                                <h2 className='-title'>{tr(`front.item_page.core.video.note.title`, {}, 'front_office-video')}</h2>
                                <p className='-text'>
                                    {!video.description || video.description.length <= 0 ? tr(`front.item_page.core.video.note.placeholder${isPictorial ? '_img' : ''}`, {}, 'front_office-video') : video.description}
                                </p>
                                <Button variant='contained' onClick={() => this.setState({
                openModalEditNote: true
              })}>
                                    {!video.description || video.description.length <= 0 ? tr(`front.item_page.core.video.note.buttons.add`, {}, 'front_office-video') : tr(`front.item_page.core.video.note.buttons.edit`, {}, 'front_office-video')}
                                </Button>
                            </div>
                        </div>
                        <ShareHistoryPanel video={video} socialPosts={socialPosts} mutateSocialPosts={mutateSocialPosts} isLoadingSocialPosts={isLoadingSocialPosts} isValidatingSocialPosts={isValidatingSocialPosts} />
                    </div>
                    {appuser && appuser.activated() === true && <div className='-snippets'>
                            <div className='-item'>
                                <div className='-nav'>
                                    {isNetworkShareButtonVisible() && <div className='-item' onClick={() => router.push(`/video/share/${this.props.video.shortId}`)}>
                                            <FavoriteBorderIcon className='-icon' width={16} />
                                            <p className='-text'>{tr(`front.snippet.navigation.share`, {}, 'front_office-video')}</p>
                                        </div>}
                                    {i18n.defaultLang === 'fr' && <div className='-item'>
                                            <RocketLaunchIcon className='-icon' width={16} />
                                            <a href='https://danim.com/boost' target='_blank' rel='noopener noreferrer' className='-text , text-decoration-none'>
                                                {tr(`front.snippet.navigation.sponsored_campaign`, {}, 'front_office-video')}
                                            </a>
                                        </div>}
                                    {video?.file?.src !== undefined && video?.file?.src !== null && !isPictorial && <div className='-item' onClick={() => this.setState({
                openModalThumbnail: true
              })}>
                                            <AddPhotoAlternateOutlinedIcon className='-icon' width={16} />
                                            <p className='-text'>{tr(`front.snippet.navigation.thumbnail`, {}, 'front_office-video')}</p>
                                        </div>}
                                    {video.thumbnail !== null && video.thumbnail?.src !== null && isPictorial && <div className='-item' onClick={() => this.setState({
                openModalImageInFullscreen: true
              })}>
                                            <OpenInFullIcon className='-icon' width={16} />
                                            <p className='-text'>{tr('front.snippet.navigation.fullscreen', {}, 'front_office-video')}</p>
                                        </div>}
                                    <div className='-item' onClick={this.handleShareClick}>
                                        <ContentCopyIcon className='-icon' width={16} />
                                        <p className='-text'>{tr(`front.snippet.navigation.copy`, {}, 'front_office-video')}</p>
                                    </div>
                                    {(video.file !== null && video.file?.src !== null || video.thumbnail !== null && video.thumbnail?.src !== null) && <div className='-item' onClick={this.handleDownloadClick}>
                                            <FileDownloadIcon className='-icon' width={16} />
                                            <p className='-text'>
                                                {tr(`front.snippet.navigation.download${isPictorial ? '_img' : ''}`, {}, 'front_office-video')}
                                            </p>
                                        </div>}
                                    {(!_.includes(['DRAFT', 'BEING_COMPLETED', 'TO_BE_COMPLETED', 'ERROR'], this.state.state) && !!video.template && templateState !== 'DRAFT') === true && <div className='-item' onClick={() => router.push(`/video/edit/${video.shortId}`)}>
                                            <FormatShapesIcon className='-icon' width={16} />
                                            <p className='-text'>
                                                {tr(`front.snippet.navigation.edit${isPictorial ? '_img' : ''}`, {}, 'front_office-video')}
                                            </p>
                                        </div>}
                                    {(!_.includes(['DRAFT', 'BEING_COMPLETED', 'TO_BE_COMPLETED', 'ERROR'], this.state.state) && !!video.template && templateState !== 'DRAFT') === true && <div className='-item' onClick={() => router.push(`/video/new?templateShortId=${video.template.shortId}&prefillVideoId=${video.id}`)}>
                                            <VideoLibraryOutlinedIcon className='-icon' width={16} />
                                            <p className='-text'>
                                                {tr(`front.snippet.navigation.duplicate${isPictorial ? '_img' : ''}`, {}, 'front_office-video')}
                                            </p>
                                        </div>}
                                    {(this.state.state === 'ERROR' && !video.generatedInCloud) === true && !!video.template === true && <div className='-item' onClick={this.handleReinitializeClick}>
                                            <ReplayOutlinedIcon className='-icon' width={16} />
                                            <p className='-text'>
                                                {tr(`front.item_page.forms.snippet.reinitialize${isPictorial ? '_img' : ''}`, {}, 'front_office-video')}
                                            </p>
                                        </div>}
                                    {!!(appuser && appuser.activated() &&
              // (
              !video.generatedInCloud && this.state.state !== 'DONE' && templateState !== 'DRAFT') === true && <div className='-item' onClick={this.handleBackToDraftClick}>
                                            <UndoOutlinedIcon className='-icon' width={16} />
                                            <p className='-text'>
                                                {tr(`front.item_page.forms.snippet.${video.generatedInCloud && video.watermarked ? 'modify' : 'back_to_draft'}`, {}, 'front_office-video')}
                                            </p>
                                        </div>}
                                </div>
                            </div>
                            <RatioVariationPanel isLoadingVideo={isLoadingVideo} video={video} workspaceID={workspaceID} mutateVideo={mutateVideo} appuser={appuser} />
                        </div>}
                </div>
            </main>;
  };
}
ItemPage.propTypes = {
  // required
  video: PropTypes.object.isRequired
};
export default ItemPage;