import { Network } from '@components/office/front-office/organization/social/types/Network';
import { faLinkedin as LinkedinIcon } from '@fortawesome/free-brands-svg-icons';
import { HumanRatioEnum } from '@components/office/front-office/organization/social/enums/HumanRatioEnum';
const LinkedinConfiguration = (): Network => {
  return {
    name: 'linkedin',
    title: 'Linkedin',
    subtitleFieldName: 'actualOrganizationPage',
    icon: LinkedinIcon,
    color: '#0077B5',
    rules: {
      video: {
        types: {
          post: {
            title: {
              required: false
            },
            description: {
              required: false
            },
            availableHumanRatio: [HumanRatioEnum.SQUARE, HumanRatioEnum.LANDSCAPE, HumanRatioEnum.PORTRAIT]
          },
          reel: false,
          story: false,
          short: false
        },
        defaultType: 'post',
        durations: {
          post: {
            min: 0,
            max: 15 * 60
          },
          reel: {
            min: 0,
            max: 0
          },
          story: {
            min: 0,
            max: 0
          },
          short: {
            min: 0,
            max: 0
          }
        }
      },
      picture: {
        types: {
          post: {
            title: {
              required: false
            },
            description: {
              required: false
            },
            availableHumanRatio: [HumanRatioEnum.SQUARE, HumanRatioEnum.LANDSCAPE, HumanRatioEnum.PORTRAIT]
          },
          reel: false,
          story: false,
          short: false
        },
        defaultType: 'post'
      }
    },
    enabled: true,
    editorOptions: {
      toolbar: ['emoticons']
    }
  };
};
export default LinkedinConfiguration;