import { styled } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
export const DraggableHelperDialogWrapper = styled('div')``;
interface CustomDialogContentProps {
  height?: string;
  width?: string;
}
export const CustomDialogContent = styled(DialogContent)<CustomDialogContentProps>`
    overflow: hidden;
    min-height: 200px;
    min-width: 350px;
    iframe {
        border-radius: 0.5rem;
        border: none;
        width: 100%;
        height: 100%;
        user-select: none;
    }
`;
export const CustomDialogTitle = styled(DialogTitle)`
    padding: 16px 16px;
    user-select: none;
`;
export const CustomDialogActions = styled(DialogActions)`
    padding: 0 24px 16px 24px;
    flex-wrap: wrap;
    justify-content: center;

    button {
        flex: 1;
    }
`;