function queryVideoShowSocialPosts(id?: string): string | null {
    return id
        ? `query videoPayloadShowSocialPosts {
            videoPayloadShowSocialPosts:
                video (shortId: "${id}") {
                    socialPosts {
                        id
                        description
                        title
                        scheduledFor
                        postedAt
                        state
                        error
                        link
                        networkSettings {
                            __typename
                            target
                            ... on VideoSocialPostYoutubeSettings {
                                privacyStatus
                                playlistId
                                playlist {
                                    id
                                    name
                                    channelId
                                    channel {
                                      id
                                      name
                                    }
                                }
                            }
                            ... on VideoSocialPostTiktokSettings {
                                privacyLevel
                                commentDisabled
                                duetDisabled
                                stitchDisabled
                                discloseContent
                                forMyBrand
                                forThirdBrand
                            }
                            ... on SocialPostYoutubeSettings {
                                privacyStatus
                                playlistId
                                playlist {
                                    id
                                    name
                                    channelId
                                    channel {
                                      id
                                      name
                                    }
                                }
                            }
                            ... on SocialPostTiktokSettings {
                                privacyLevel
                                commentDisabled
                                duetDisabled
                                stitchDisabled
                                discloseContent
                                forMyBrand
                                forThirdBrand
                            }
                        }
                        formats {
                            format
                            daughterVideoId
                            daughterVideo {
                                humanRatio
                            }
                            actualVideoId
                            actualVideo {
                                id
                            }
                        }
                    }
                }
        }`
        : null;
}

const queryFrontVideoShowSocialPostsMock = () => 'FrontVideoVideoShowSocialPosts';

const queryFrontVideoShowSocialPostsApi =
    process.env.NEXT_PUBLIC_MOCKS === 'true' ? queryFrontVideoShowSocialPostsMock : queryVideoShowSocialPosts;

export default queryFrontVideoShowSocialPostsApi;
