import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import dynamic from 'next/dynamic';
import { HumanRatio } from '@components/office/front-office/organization/social/types/HumanRatio';
type Props = {
  video: any;
  appuser: any;
  workspace: any;
  format: HumanRatio;
  isOpen: boolean;
  isRemoveDaughter: boolean;
  prefillDaughterId: string;
  motherId: string;
  onClose: () => void;
  onSave: () => void;
  mutateVideo: () => void;
};
const StudioPage = dynamic(() => import('@components/office/front-office/video/StudioPage'), {
  ssr: false
});
const ModalGenerate = (props: Props) => {
  const {
    video,
    appuser,
    workspace,
    format,
    isOpen,
    isRemoveDaughter,
    prefillDaughterId,
    motherId,
    onClose,
    onSave,
    mutateVideo
  } = props;
  return <Dialog open={isOpen} onClose={onClose} maxWidth='xl' fullWidth>
            <DialogTitle>{tr(`front.video.modal.generate.title`, {}, 'front_office-video')}</DialogTitle>
            <DialogContent>
                <StudioPage video={video} template={video.template} generationVariables={video?.generationVariables || []} prefill={null} appuser={appuser} workspace={workspace} format={format} onSave={onSave} isRemoveDaughter={isRemoveDaughter} prefillDaughterId={prefillDaughterId} motherId={motherId} mutateVideo={mutateVideo} />
            </DialogContent>
        </Dialog>;
};
export default ModalGenerate;