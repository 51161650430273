import React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
type Props = {
  videoNote: string;
  isOpen: boolean;
  onClose: () => void;
  onChange: (e) => void;
  onSave: () => void;
  isPictorial?: boolean;
};
const ModalEditNote = (props: Props) => {
  const {
    videoNote,
    isOpen,
    onClose,
    onChange,
    onSave,
    isPictorial = false
  } = props;
  return <Dialog open={isOpen} onClose={onClose} maxWidth='sm' fullWidth>
            <DialogTitle>{tr(`front.video.modal.edit_note.title${isPictorial ? '_img' : ''}`, {}, 'front_office-video')}</DialogTitle>
            <DialogContent>
                <TextField autoFocus multiline rows={4} label={tr(`front.video.modal.edit_note.label`, {}, 'front_office-video')} type='text' fullWidth name='description' variant='standard' onChange={e => onChange(e)} value={videoNote} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{tr(`front.video.modal.edit_note.buttons.cancel`, {}, 'front_office-video')}</Button>
                <Button onClick={onSave}>{tr(`front.video.modal.edit_note.buttons.save`, {}, 'front_office-video')}</Button>
            </DialogActions>
        </Dialog>;
};
export default ModalEditNote;