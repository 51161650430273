import __Base from '@components/Base';
import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Progress } from 'reactstrap';
import DraggableHelperDialog from '@components/bridge/bridge/DraggableHelperDialog';
class CreditsBar extends __Base {
  render = () => {
    const {
      customAttributes,
      title,
      appuser,
      templateId = null
    } = this.props;
    let plan;
    let creditsProgressPercentage;
    let storageProgressPercentage;
    let storageLeft;
    let storageLimit;
    let storageUsed;
    let humanStorageLeft;
    let humanStorageLimit;
    if (appuser) {
      plan = _.get(appuser, 'organization.plan');
      if (plan) {
        creditsProgressPercentage = plan.gaugeCapacity ? _.round(100 * _.get(plan, 'credits') / plan.gaugeCapacity, 1) : null;
        storageLimit = appuser.adjustedStorageLimit();
        if (storageLimit !== null) {
          storageUsed = appuser.usedStorage();
          storageLeft = Math.max(0, storageLimit - storageUsed);
          storageProgressPercentage = storageLimit ? Math.max(0, _.round(100 * storageLeft / storageLimit)) : 0;
          humanStorageLeft = _.toString(storageLeft ? humanSize(Math.max(0, storageLeft), 2, tr('front.storage_base_unit.for_factor.1000', {}, 'bridge-bridge'), '') : 0);
          humanStorageLimit = _.toString(storageLimit ? humanSize(Math.max(0, storageLimit), 2, tr('front.storage_base_unit.for_factor.1000', {}, 'bridge-bridge'), '') : 0);
          const splitForHumanStorageLeft = humanStorageLeft.split(' ');
          const splitForHumanStorageLimit = humanStorageLimit.split(' ');
          if (splitForHumanStorageLeft.length > 1 && splitForHumanStorageLimit.length > 1 && splitForHumanStorageLeft[splitForHumanStorageLeft.length - 1] === splitForHumanStorageLimit[splitForHumanStorageLimit.length - 1]) humanStorageLeft = splitForHumanStorageLeft.slice(0, -1).join(' ');
        }
      }
    }
    return <div {..._.get(customAttributes, 'root', {})} className={clsx('creditsBar', _.get(customAttributes, 'root.className'))}>
                {title}
                {appuser && appuser.isOrganizationDirector() === true && <div className='-caption'>
                        <span className='-texts'>
                            {appuser.organization?.plan?.traits?.visibleCreditsGauge?.value && <span className={clsx('-text /credits', creditsProgressPercentage <= 20 && creditsProgressPercentage > 0 && '/low', creditsProgressPercentage === 0 && '/none')} dangerouslySetInnerHTML={{
            __html: tr('front.credits_bar.caption.credits.ongoing.not_expiring', _.get(plan, 'credits'))
          }} />}
                            {storageLimit !== null && <span className={clsx('-text /storage', storageProgressPercentage <= 20 && storageProgressPercentage > 0 && '/low', storageProgressPercentage === 0 && '/none')} dangerouslySetInnerHTML={{
            __html: tr('front.credits_bar.caption.storage', {
              left: humanStorageLeft,
              on: humanStorageLimit
            }, 'bridge-bridge')
          }} />}
                        </span>
                        <span className='-progresses'>
                            {appuser.organization?.plan?.traits?.visibleCreditsGauge?.value && <Progress className={clsx('-progress /credits', creditsProgressPercentage <= 20 && creditsProgressPercentage > 0 && '/low', creditsProgressPercentage === 0 && '/none')} value={creditsProgressPercentage} />}
                            {!!storageLimit && <Progress className={clsx('-progress /storage', storageProgressPercentage <= 20 && storageProgressPercentage > 0 && '/low', storageProgressPercentage === 0 && '/none')} value={storageProgressPercentage} />}
                        </span>
                    </div>}
                {templateId === '606e4f32-bab7-4c44-8f94-e668f4702507' && <DraggableHelperDialog dialogTitle={tr('front.home.dialogs.tutorial.helper.title')} draggableId='draggable-custom-template' url='https://www.youtube.com/embed/M-NZxvkO5E0?si=oml8fJT0ZempIta2' chipSize='medium' />}
            </div>;
  };
}
CreditsBar.defaultProps = {
  customAttributes: {}
};
CreditsBar.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  customAttributes: PropTypes.object
};
export default CreditsBar;