import * as React from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Chip, useMediaQuery } from '@mui/material';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { CustomDialogActions, CustomDialogContent, CustomDialogTitle, DraggableHelperDialogWrapper } from '@components/bridge/bridge/DraggableHelperDialog.styles';
import { useCallback, useMemo, useState } from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Resizable } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { Portal } from '@mui/base';
import { motion, useDragControls } from 'framer-motion';
import dialogHelper from '@components/bridge/bridge/DraggableHelperDialog.anim';
import { useRouter } from 'next/router';
import useTheme from '@mui/system/useTheme';
import get from 'lodash/get';
type DraggableHelperDialogProps = {
  draggableId: string;
  helperText?: string;
  helperIcon?: any;
  dialogTitle: string;
  youtubeId?: string;
  url?: string;
  ctaUrl?: string;
  ctaIcon?: any;
  ctaText?: string;
  chipSize?: 'small' | 'medium';
};
function DraggableHelperDialog({
  draggableId,
  helperText = 'Tuto',
  helperIcon = <PlayArrowRoundedIcon />,
  ctaIcon = null,
  dialogTitle,
  youtubeId,
  url,
  ctaUrl = '',
  ctaText = tr('front.helper.cta.modify'),
  chipSize = 'medium'
}: DraggableHelperDialogProps) {
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dragControls = useDragControls();
  const [height, setHeight] = useState(320);
  const [width, setWidth] = useState(isMobile ? 420 : 580);
  const [open, setOpen] = useState(false);
  const handleResize = useCallback((event, {
    size
  }) => {
    setHeight(size.height);
    setWidth(size.width);
  }, []);
  const handleClickOpen = useCallback(() => {
    if (open === true) setOpen(false);else setOpen(true);
  }, [open]);
  const handleClose = (event, reason) => {
    setOpen(false);
  };
  const handleCtaClick = useCallback(() => {
    window.open(ctaUrl, '_blank');
    setOpen(false);
  }, [ctaUrl]);
  const urlVideo = youtubeId ? `https://www.youtube.com/embed/${youtubeId}?autoplay=1&controls=1&rel=0&showinfo=0&modestbranding=1&fs=1&playsinline=1` : url || null;
  const iframeVideo = useMemo(() => urlVideo ? <iframe loading='lazy' src={urlVideo} allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope' allowFullScreen /> : null, [urlVideo]);
  const handlePaperComponentRef = useCallback(node => {
    if (node !== null) {
      node.style.height = `${height}px`;
      node.style.width = `${width}px`;
    }
  }, [height, width]);
  const handleOnDragStart = (event: any, info) => {
    const target = get(event, 'target.className', '');

    // if event.target is not the title of the draggable, then we cancel the drag
    if (target && !target.includes('MuiDialogTitle-root')) {
      // @ts-ignore
      dragControls.componentControls.forEach(entry => {
        entry.stop(event, info);
      });
    }
  };
  return <DraggableHelperDialogWrapper data-test-id={draggableId}>
            <Chip size={chipSize} color='info' icon={helperIcon || <PlayArrowRoundedIcon />} label={helperText || 'Tuto'} onClick={handleClickOpen} />
            <Portal container={document.querySelector('.draggable-root')}>
                {open && <motion.div drag dragConstraints={{
        top: 0,
        right: 0
      }} dragMomentum={false} initial='init' animate={open ? 'open' : 'closed'} variants={dialogHelper} dragControls={dragControls} onDragStart={handleOnDragStart} custom={router.route}>
                        <Resizable height={height} width={width} onResize={handleResize} minConstraints={[300, 274]} maxConstraints={[1000, 556]} resizeHandles={['sw']}>
                            <Paper elevation={12} sx={{
            width: '100%',
            height: '100%'
          }}>
                                <CustomDialogTitle style={{
              cursor: 'move'
            }} id={draggableId}>
                                    <DragIndicatorIcon />
                                    {dialogTitle}
                                </CustomDialogTitle>
                                <CustomDialogContent ref={handlePaperComponentRef}>{open && iframeVideo}</CustomDialogContent>
                                <CustomDialogActions>
                                    <Button variant='outlined' onClick={e => handleClose(e, 'CTA')}>
                                        {tr('front.home.dialogs.data.helper.close_modal')}
                                    </Button>
                                    {ctaUrl && <Button startIcon={ctaIcon} variant='gradient' onClick={handleCtaClick}>
                                            {ctaText || tr('front.helper.cta.more')}
                                        </Button>}
                                </CustomDialogActions>
                            </Paper>
                        </Resizable>
                    </motion.div>}
            </Portal>
        </DraggableHelperDialogWrapper>;
}
export default React.memo(DraggableHelperDialog);