import React from 'react';
import { SocialNetworkShare } from '@components/office/front-office/organization/social/types/SocialNetworkShare';
import SocialNetworkShareItem from '@components/office/front-office/video/SocialNetworkShareAccordion/Item/SocialNetworkShareItem';
type Props = {
  mutateSocialPosts: any;
  shares: SocialNetworkShare[];
  isLoadingSocialPosts: boolean;
  isValidatingSocialPosts: boolean;
};
const SocialNetworkShareItems = (props: Props) => {
  const {
    shares,
    mutateSocialPosts,
    isLoadingSocialPosts,
    isValidatingSocialPosts
  } = props;
  return <>
            {shares.map(share => share && share.id ? <SocialNetworkShareItem mutateSocialPosts={mutateSocialPosts} share={share} key={share.id} isLoadingSocialPosts={isLoadingSocialPosts} isValidatingSocialPosts={isValidatingSocialPosts} /> : null)}
        </>;
};
export default SocialNetworkShareItems;