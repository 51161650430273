import React from 'react';
import { mutate } from 'swr';
import useSWRImmutable from 'swr/immutable';

export default function useSharedState(id, initialState?) {
    const { data } = useSWRImmutable(id, () => (typeof initialState === 'function' ? initialState() : initialState));

    const setState = React.useCallback(
        fnOrData => {
            mutate(id, typeof fnOrData === 'function' ? fnOrData(data) : fnOrData, false).catch(console.error);
        },
        [id, data]
    );

    if (!data) return [false, setState];

    return [data, setState];
}
