import React, { useMemo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
};
const ModalDelete = (props: Props) => {
  const {
    isOpen,
    onClose,
    onSave
  } = props;
  return <Dialog open={isOpen} onClose={onClose} maxWidth='sm' fullWidth>
            <DialogTitle>{tr(`front.item_page.core.share.modal_delete.title`)}</DialogTitle>
            <DialogContent>{tr(`front.item_page.core.share.modal_delete.description`)}</DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{tr(`front.item_page.core.share.modal_delete.buttons.cancel`)}</Button>
                <Button onClick={onSave}>{tr(`front.item_page.core.share.modal_delete.buttons.save`)}</Button>
            </DialogActions>
        </Dialog>;
};
export default ModalDelete;