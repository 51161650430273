function queryVideoShow(id?: string): string | null {
    return id
        ? `query queryVideoShow {
            videoPayloadShowVideo:
                video (shortId: "${id}") {
                    id
                    createdAt
                    shortId
                    name
                    minName: name (minimalistic: true)
                    state
                    description
                    ratio
                    pictorial
                    templateData
                    generatedInCloud
                    generationVariables {
                        sourceMap
                        data
                    }
                    creatorId
                    organizationId
                    templateId
                    workspaceId
                    watermarked
                    motherId
                    duration
                    humanRatio
                    availableHumanRatios
                    daughterIds
                    daughters {
                        edges {
                            node {
                                id
                                createdAt
                                shortId
                                name
                                minName: name (minimalistic: true)
                                state
                                description
                                ratio
                                templateData
                                generatedInCloud
                                generationVariables {
                                    sourceMap
                                    data
                                }
                                creatorId
                                organizationId
                                templateId
                                workspaceId
                                watermarked
                                motherId
                                duration
                                humanRatio
                                availableHumanRatios
                                file {
                                    src (absolute: true)
                                }
                                thumbnail {
                                    id
                                    src (absolute: true)
                                }
                                socialPostSettings {
                                    thumbnail {
                                        src (absolute: true)
                                    }
                                    thumbnailTime
                                }
                                progress {
                                    duration (remaining: true)
                                    percentage
                                    nextPercentage: percentage (next: true)
                                }
                                creator {
                                    id
                                    profile {
                                        names {
                                            both
                                        }
                                    }
                                }
                                template {
                                    id
                                    shortId
                                    state
                                    suggestedThirdMusicIds
                                    config
                                    dynamic
                                    name
                                    generationCost
                                    libraryId
                                    organizationId
                                    readyForCloudUse (byHeredity: true)
                                    forCloudGenerations
                                    pictorial
                                    generationVariables {
                                        sourceMap
                                        data
                                    }
                                    mainSource {
                                        creatorId
                                        byAdmin
                                        wasValidBeforeTest
                                        state
                                        open
                                    }
                                    preview {
                                        src (absolute: true)
                                    }
                                    thumbnail {
                                        src (absolute: true)
                                    }
                                }            
                            }
                        }
                    }
                    file {
                        src (absolute: true)
                    }
                    thumbnail {
                        id
                        src (absolute: true)
                    }
                    socialPostSettings {
                        thumbnail {
                            src (absolute: true)
                        }
                        thumbnailTime
                    }
                    progress {
                        duration (remaining: true)
                        percentage
                        nextPercentage: percentage (next: true)
                    }
                    creator {
                        id
                        profile {
                            names {
                                both
                            }
                        }
                    }
                    template {
                        id
                        shortId
                        state
                        suggestedThirdMusicIds
                        config
                        dynamic
                        name
                        generationCost
                        libraryId
                        organizationId
                        readyForCloudUse (byHeredity: true)
                        forCloudGenerations
                        pictorial
                        generationVariables {
                            sourceMap
                            data
                        }
                        mainSource {
                            creatorId
                            byAdmin
                            wasValidBeforeTest
                            state
                            open
                        }
                        preview {
                            src (absolute: true)
                        }
                        thumbnail {
                            src (absolute: true)
                        }
                    }
                    socialPosts {
                        id
                        description
                        title
                        scheduledFor
                        postedAt
                        state
                        error
                        link
                        networkSettings {
                            __typename
                            target
                            ... on VideoSocialPostYoutubeSettings {
                                privacyStatus
                                playlistId
                                playlist {
                                    id
                                    name
                                    channelId
                                    channel {
                                      id
                                      name
                                    }
                                }
                            }
                            ... on VideoSocialPostTiktokSettings {
                                privacyLevel
                                commentDisabled
                                duetDisabled
                                stitchDisabled
                                discloseContent
                                forMyBrand
                                forThirdBrand
                            }
                            ... on SocialPostYoutubeSettings {
                                privacyStatus
                                playlistId
                                playlist {
                                    id
                                    name
                                    channelId
                                    channel {
                                      id
                                      name
                                    }
                                }
                            }
                            ... on SocialPostTiktokSettings {
                                privacyLevel
                                commentDisabled
                                duetDisabled
                                stitchDisabled
                                discloseContent
                                forMyBrand
                                forThirdBrand
                            }
                        }
                        formats {
                            format
                            daughterVideoId
                            daughterVideo {
                                humanRatio
                            }
                            actualVideoId
                            actualVideo {
                                id
                            }
                        }
                    }
                }
        }`
        : null;
}

const queryFrontVideoShowMock = () => 'FrontVideoVideoShow';

const queryFrontVideoShowApi = process.env.NEXT_PUBLIC_MOCKS === 'true' ? queryFrontVideoShowMock : queryVideoShow;

export default queryFrontVideoShowApi;
