import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Divider from '@mui/material/Divider';
import { fr } from 'date-fns/locale';
import { formatDistance } from 'date-fns';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { pink } from '@mui/material/colors';
import Link from '@mui/material/Link';
import TooltipBox from '@components/bridge/bridge/TooltipBox';
import { axios } from '@services/http/client';
import { useRouter } from 'next/router';
import { SocialNetworkShare } from '@components/office/front-office/organization/social/types/SocialNetworkShare';
import ModalDelete from './ModalDelete';
type Props = {
  mutateSocialPosts: any;
  share: SocialNetworkShare;
  isLoadingSocialPosts: boolean;
  isValidatingSocialPosts: boolean;
};
const SocialNetworkShareItem = (props: Props) => {
  const {
    mutateSocialPosts,
    share,
    isLoadingSocialPosts,
    isValidatingSocialPosts
  } = props;
  const router = useRouter();
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isDeleteSuccess, setIsDeleteSuccess] = useState<boolean>(false);
  const [deletedSocialPostId, setDeletedSocialPostId] = useState<string | null>(null);
  const handleChange = useCallback(() => {
    setExpanded((prevExpanded: boolean) => !prevExpanded);
  }, []);
  const handleDelete = useCallback(async () => {
    const underWhitelabel = !window.areWeUnderDanimHost();
    const body = {
      videoId: share.videoId,
      postId: share.id
    };
    const options = {
      method: 'POST',
      url: gen('write/video/discard-social-post'),
      data: body,
      underWhitelabel
    };
    await axios(options, {
      then: () => {
        setDeletedSocialPostId(share.id);
        mutateSocialPosts();
        setIsDeleteSuccess(true);
      },
      catch: e => {
        addFlash({
          type: 'warning',
          message: tr('front.item_page.core.share.modal_delete.error', {}, 'front_office-video')
        });
        console.error(e);
        setIsOpenModalDelete(false);
      }
    });
  }, [share]);
  const handleRetry = useCallback(async () => {
    const underWhitelabel = !window.areWeUnderDanimHost();
    const body = {
      videoId: share.videoId,
      postId: share.id
    };
    const options = {
      method: 'POST',
      url: gen('write/video/retry-social-post'),
      data: body,
      underWhitelabel
    };
    await axios(options, {
      then: () => {
        addFlash({
          type: 'success',
          message: tr('front.item_page.core.share.retry.success', {}, 'front_office-video')
        });
        mutateSocialPosts();
      },
      catch: e => {
        addFlash({
          type: 'warning',
          message: tr('front.item_page.core.share.retry.error', {}, 'front_office-video')
        });
        console.error(e);
      }
    });
  }, [share]);
  useEffect(() => {
    if (isDeleteSuccess && !isLoadingSocialPosts && isOpenModalDelete && isValidatingSocialPosts && deletedSocialPostId) {
      setIsOpenModalDelete(false);
      setIsDeleteSuccess(false);
      mutateSocialPosts().then(updatedSocialPosts => {
        if (!updatedSocialPosts.socialPosts.find((post: SocialNetworkShare) => post.id === deletedSocialPostId)) {
          addFlash({
            type: 'success',
            message: tr('front.item_page.core.share.modal_delete.success', {}, 'front_office-video')
          });
          setDeletedSocialPostId(null);
        }
      });
    }
  }, [isDeleteSuccess, isLoadingSocialPosts, isOpenModalDelete, isValidatingSocialPosts, deletedSocialPostId, mutateSocialPosts]);
  return <>
            {isOpenModalDelete && <ModalDelete isOpen={isOpenModalDelete} onClose={() => setIsOpenModalDelete(false)} onSave={handleDelete} />}
            <Accordion expanded={expanded}>
                <AccordionSummary aria-controls={`${share.id}-content`} id={`${share.id}-header`} expandIcon={<ExpandMoreIcon style={{
        cursor: 'pointer',
        visibility: share.description ? 'visible' : 'hidden'
      }} onClick={handleChange} />} sx={{
        cursor: 'unset !important'
      }}>
                    <Typography sx={{
          width: '7%',
          flexShrink: 0
        }}>
                        <FontAwesomeIcon color={share.network.color} icon={share.network.icon} size='xl' />
                    </Typography>
                    <Typography sx={{
          width: '10%',
          flexShrink: 0,
          fontSize: 15,
          fontWeight: 600
        }}>
                        {share.formats && share.formats.length > 0 ? share.formats[0]?.format : 'N/A'}
                    </Typography>
                    <Divider orientation='vertical' flexItem />
                    <Typography sx={{
          width: '10%',
          marginLeft: 3,
          fontSize: 15,
          fontWeight: 600
        }}>
                        {share.formats && share.formats.length > 0 ? share.formats[0]?.daughterVideo?.humanRatio !== undefined ? tr(`front.item_page.core.share.type.scheduled.${share.formats[0]?.daughterVideo?.humanRatio}.label`) : tr(`front.item_page.core.share.type.scheduled.${share.videoHumanRatio}.label`) : tr(`front.item_page.core.share.type.scheduled.${share.videoHumanRatio}.label`)}
                    </Typography>
                    <Divider orientation='vertical' flexItem />
                    {(share.scheduledAt || share.postedAt) && <TooltipBox caption={new Date(share.state === 'DONE' ? share.postedAt : share.scheduledAt).toLocaleString('fr', {
          timeZone: 'Europe/Paris'
        })} style={{
          top: '-100%',
          transform: 'translateY(-50%)',
          fontWeight: 'initial',
          fontSize: 'initial'
        }}>
                            <span style={{
            width: '30%',
            marginLeft: '1.5rem',
            fontSize: 15,
            fontWeight: 500,
            color: '#3194A5'
          }}>
                                {tr(`front.item_page.core.share.type.scheduled.title`, {}, 'front_office-video')}{' '}
                                {formatDistance(new Date(share.state === 'DONE' ? share.postedAt : share.scheduledAt), new Date(), {
              addSuffix: true,
              locale: fr,
              includeSeconds: true
            })}
                            </span>
                        </TooltipBox>}
                    {(share.state === 'PENDING' || share.state === 'DRAFT' || share.state === 'ERROR') && <div style={{
          width: '10%',
          textAlign: 'right',
          fontSize: 15
        }}>
                            <TooltipBox caption={tr(`front.item_page.core.share.edit`, {}, 'front_office-video')} style={{
            top: '-100%',
            transform: 'translateY(-50%)',
            fontWeight: 'initial',
            fontSize: 'initial'
          }}>
                                <CreateOutlinedIcon onClick={e => {
              e.preventDefault();
              router.push(`video/share/edit/${share.videoShortId}/${share.id}`);
            }} sx={{
              cursor: 'pointer'
            }} />
                            </TooltipBox>
                        </div>}
                    <div style={{
          width: '1%',
          marginLeft: '1rem',
          fontSize: 15
        }}>
                        <TooltipBox caption={tr(`front.item_page.core.share.delete`, {}, 'front_office-video')} style={{
            top: '-100%',
            transform: 'translateY(-50%)',
            fontWeight: 'initial',
            fontSize: 'initial'
          }}>
                            <DeleteSweepOutlinedIcon sx={{
              color: pink[500],
              cursor: 'pointer'
            }} onClick={e => {
              e.preventDefault();
              setIsOpenModalDelete(true);
            }} />
                        </TooltipBox>
                    </div>
                    {share.state === 'ERROR' && <div style={{
          width: '1%',
          marginLeft: '2rem',
          fontSize: 15
        }}>
                            <TooltipBox caption={tr(`front.item_page.core.share.retry.title`, {}, 'front_office-video')} style={{
            top: '-100%',
            transform: 'translateY(-50%)',
            fontWeight: 'initial',
            fontSize: 'initial'
          }}>
                                <ReplayOutlinedIcon sx={{
              cursor: 'pointer'
            }} onClick={e => {
              e.preventDefault();
              handleRetry();
            }} />
                            </TooltipBox>
                        </div>}
                    {share.urlPreview && <>
                            <Typography sx={{
            width: '30%',
            marginLeft: '4rem',
            fontSize: 15,
            fontWeight: 500,
            color: '#3194A5',
            cursor: 'pointer'
          }}>
                                <Link href={share.urlPreview} underline='hover' target='_blank' rel='noopener'>
                                    {tr(`front.item_page.core.share.type.items.preview`, {}, 'front_office-video')}
                                </Link>
                            </Typography>
                        </>}
                    {share.error && <Typography sx={{
          width: '30%',
          marginLeft: '4rem',
          fontSize: 15,
          fontWeight: 500,
          color: 'red'
        }} dangerouslySetInnerHTML={{
          __html: tr(`front.item_page.core.share.error.${share.error}`, {}, 'front_office-video')
        }} />}
                </AccordionSummary>
                <AccordionDetails>
                    <p style={{
          whiteSpace: 'pre',
          overflow: 'scroll'
        }} dangerouslySetInnerHTML={{
          __html: share.description
        }} />
                </AccordionDetails>
            </Accordion>
        </>;
};
export default SocialNetworkShareItem;