enum HumanRatioEnum {
    SQUARE = 'SQUARE',
    LANDSCAPE = 'LANDSCAPE',
    PORTRAIT = 'PORTRAIT'
}

const HumanRatioLabels = {
    [HumanRatioEnum.SQUARE]: 'Carré',
    [HumanRatioEnum.LANDSCAPE]: 'Paysage',
    [HumanRatioEnum.PORTRAIT]: 'Portrait'
};

export { HumanRatioEnum, HumanRatioLabels };
