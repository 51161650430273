import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import FilterCenterFocusOutlinedIcon from '@mui/icons-material/FilterCenterFocusOutlined';
type Props = {
  videoUrl: string;
  thumbnail: {
    src: string | null;
  };
  thumbnailTime: number;
  isOpen: boolean;
  onClose: () => void;
  onSave: (thumbnail: File | null, thumbnailTime: number | null) => void;
  isLoadingSave: boolean;
};
const ModalThumbnail = (props: Props) => {
  const {
    videoUrl,
    thumbnail,
    thumbnailTime,
    isOpen,
    onClose,
    onSave,
    isLoadingSave
  } = props;
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState<string>('');
  const [fileError, setFileError] = useState<string>('');
  const [capturedAt, setCapturedAt] = useState<number | null>(null);
  const [captureReady, setCaptureReady] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number | null>(null);
  const fileSendEdited = useCallback(async (srcFile: string) => {
    if (!srcFile) return null;
    const fileResponse = await fetch(srcFile);
    const data = await fileResponse.blob();
    return new File([data], 'thumbnail.jpg', {
      type: 'image/jpeg'
    });
  }, []);
  const handleReset = useCallback(() => {
    setFile(null);
    setFileError('');
    setFilePreview('');
    setCapturedAt(null);
  }, [file]);
  const handleUploadFile = useCallback((file: FileList) => {
    if (file[0].size > 50000000) {
      setFile(null);
      setFileError(tr(`front.social_share.send.stepper.actions.upload-formats-error`, {}, 'front_office-video'));
      setCapturedAt(0);
    } else {
      setFile(file[0]);
      setFilePreview(URL.createObjectURL(file[0]));
      setCapturedAt(0);
    }
  }, [file]);
  const canvasToImg = useCallback(() => {
    const dataURLtoFile = (dataurl, filename) => {
      const url = dataurl.split('?')[0];
      let arr = url.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[arr.length - 1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime
      });
    };
    const canvasUrl = canvasRef.current.toDataURL('image/jpeg', 1.0);
    if (canvasUrl !== null) {
      const img = dataURLtoFile(canvasUrl, 'thumbnail.jpg');
      setFile(img);
      setFilePreview(URL.createObjectURL(img));
    }
  }, [canvasRef]);
  const handleCurrentTime = useCallback(() => {
    if (!captureReady) {
      console.log('not ready');
      return;
    }
    const canvas = canvasRef.current;
    const video = videoRef.current;
    video.pause();
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    setCapturedAt(video.currentTime || null);
    setFile(null);
    setFileError('');
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    canvasToImg();
  }, [videoRef, canvasRef, captureReady]);
  useEffect(() => {
    if (thumbnail?.src !== null) {
      const file = async () => {
        const data = await fileSendEdited(thumbnail?.src);
        return data;
      };
      file().then(f => {
        setCapturedAt(thumbnailTime || null);
        setFile(f);
        setFilePreview(f !== null ? URL.createObjectURL(f) : '');
        if (f === null && thumbnailTime > 0 && videoRef.current) {
          videoRef.current.currentTime = thumbnailTime;
          handleCurrentTime();
        }
      });
    } else {
      setFile(null);
      setFileError('');
      setFilePreview('');
      setCapturedAt(null);
    }
  }, [thumbnail, thumbnailTime, videoRef]);
  return <Dialog open={isOpen} onClose={onClose} maxWidth='md' fullWidth>
            <DialogTitle>{tr(`front.item_page.core.share.modal_thumbnail.title`)}</DialogTitle>
            <DialogContent>
                <p>{tr(`front.item_page.core.share.modal_thumbnail.description`)}</p>

                <div className='thumbnail'>
                    <div className='-video'>
                        {file && <img src={cors(filePreview, 'src')} alt='preview' width={400} />}
                        <video ref={videoRef} src={cors(videoUrl, 'src')} onLoadedData={(e: any) => {
            setCaptureReady(true);
            // Petit hack pour éviter un bug de chrome qui ne prend pas en compte le currentTime à 0
            setCurrentTime(0.1);
            e.target.currentTime = 0.1;
          }} onPlayCapture={(e: any) => setCurrentTime(e.target.currentTime)} onPlay={(e: any) => setCurrentTime(e.target.currentTime)} onCanPlayCapture={(e: any) => setCurrentTime(e.target.currentTime)} onChangeCapture={(e: any) => setCurrentTime(e.target.currentTime)} controls width={400} style={{
            display: capturedAt <= 0 && file === null ? 'block' : 'none'
          }} crossOrigin='anonymous' />
                        <canvas style={{
            display: 'none'
          }} ref={canvasRef} />
                    </div>
                    <div className='-actions'>
                        <>
                            {capturedAt !== null || capturedAt > 0 || file !== null ? <div className='-item'>
                                    <div className='-file-uploaded'>
                                        <div className='-text'>
                                            <div className='-icon'>
                                                <AddPhotoAlternateOutlinedIcon fontSize='large' color='primary' />
                                            </div>
                                            {tr(`front.social_share.send.stepper.actions.success_upload`, {}, 'front_office-video')}
                                        </div>
                                        <Button variant='gradient' onClick={handleReset} startIcon={<BorderColorOutlinedIcon />}>
                                            {tr(`front.social_share.send.stepper.actions.edit`, {}, 'front_office-video')}
                                        </Button>
                                    </div>
                                </div> : <>
                                    <div className='-item --capture'>
                                        <Button disabled={!captureReady || currentTime <= 0} variant='gradient' onClick={handleCurrentTime} startIcon={<FilterCenterFocusOutlinedIcon />}>
                                            {tr(`front.social_share.send.stepper.actions.capture`, {}, 'front_office-video')}
                                        </Button>
                                    </div>
                                    <div className='-item --text'>
                                        {tr(`front.social_share.send.stepper.actions.or`, {}, 'front_office-video')}
                                    </div>
                                    <div className='-item'>
                                        <div className='-file'>
                                            <label htmlFor='contained-button-file' style={{
                    cursor: 'pointer',
                    textAlign: 'center'
                  }}>
                                                <AddPhotoAlternateOutlinedIcon fontSize='large' color='primary' />
                                                <br />
                                                <br />
                                                {tr(`front.social_share.send.stepper.actions.upload`, {}, 'front_office-video')}
                                                <input style={{
                      display: 'none'
                    }} type='file' accept='image/png, image/jpeg, image/jpg' onChange={e => handleUploadFile(e.target.files)} id='contained-button-file' />
                                                <br />
                                                <br />
                                                {fileError && <div className='-text-error'>{fileError}</div>}
                                                <div className='-formats'>
                                                    {tr(`front.social_share.send.stepper.actions.upload-formats`, {}, 'front_office-video')}
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </>}
                        </>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={isLoadingSave}>
                    {tr(`front.item_page.core.share.modal_thumbnail.buttons.cancel`)}
                </Button>
                <Button onClick={() => onSave(file, capturedAt)} disabled={isLoadingSave || file === null || !capturedAt && capturedAt < 0}>
                    {tr(`front.item_page.core.share.modal_thumbnail.buttons.save`)}
                </Button>
            </DialogActions>
        </Dialog>;
};
export default ModalThumbnail;