import React, { useEffect, useState } from 'react';
import Factory from '@components/bridge/bridge/immutable/factory';
import queryAppUserApi from '@queries/api/bridge/queryAppUser';
import { useRouter } from 'next/router';
import useSharedState from '@hooks/useSharedState';
import ItemPage from '@components/office/front-office/video/ItemPage';
import useSWR from 'swr';
import { apiSwrRequest, logout } from '@services/http/client';
import Loader from '@components/bridge/bridge/Loader';
import queryFrontVideoShowApi from '@queries/api/office/front-office/video/queryVideoShow';
import queryFrontVideoShowSocialPostsApi from '@queries/api/office/front-office/video/queryVideoShowSocialPosts';
import TemplateLibrary from '@components/office/front-office/template/TemplateLibrary';
import User from '@utils/User';
function Video() {
  const router = useRouter();
  if (!router.query?.video) {
    router.push('/').then(() => {
      addFlash({
        message: tr('back.controller.show_item.redirect_message.does_not_exist'),
        type: 'error'
      }, 2000);
    });
  }
  const {
    data: me
  } = User.useLoadMe();
  const {
    data: appuser
  } = useSWR(() => queryAppUserApi(me.id), data => apiSwrRequest(data, me?.__typename === 'User' ? 'bridgeUser' : 'bridgeApiApp'));
  const {
    data: video,
    isValidating: isValidatingVideo,
    mutate: mutateVideo
  } = useSWR(() => queryFrontVideoShowApi((router.query?.video as string)), data => apiSwrRequest(data, 'videoPayloadShowVideo'));
  const {
    data: socialPosts,
    isLoading: isLoadingSocialPosts,
    isValidating: isValidatingSocialPosts,
    mutate: mutateSocialPosts
  } = useSWR(() => queryFrontVideoShowSocialPostsApi((router.query?.video as string)), data => apiSwrRequest(data, 'videoPayloadShowSocialPosts'), {
    refreshInterval: 2000
  });
  const [meta, setMeta] = useSharedState('meta', {
    title: ''
  });
  useEffect(() => {
    if (video === undefined) return;
    setMeta({
      ...meta,
      image: {
        src: video?.template?.thumbnail?.src
      },
      video: {
        src: video?.template?.preview?.src
      },
      title: video?.minName
    });
  }, [meta]);
  const [appUserCreate, setAppUserCreate] = useState<any>(null);
  useEffect(() => {
    if (!appuser) return;
    setAppUserCreate(Factory.create('AppUser', appuser, false));
  }, [appuser]);
  useEffect(() => {
    if (!appUserCreate) return;
    TemplateLibrary.potentiallyBlockUnlicensedUser(appUserCreate, router);
    TemplateLibrary.potentiallyBlockPausedUser(appUserCreate, router);
  }, [appUserCreate, router]);
  if (me === undefined) return <div className='innerLoader'>
                <Loader />
            </div>;
  if (!me?.id) {
    logout().catch(console.error);
    return null;
  }
  if (appuser === undefined || video === undefined) return <div className='innerLoader'>
                <Loader />
            </div>;
  if (!video) {
    router.push('/').then(() => {
      addFlash({
        message: tr('back.controller.show_item.redirect_message.does_not_exist'),
        type: 'error'
      }, 2000);
    });
    return null;
  }
  if (!video?.id) {
    router.push('/').then(() => {
      addFlash({
        message: tr('back.controller.redirect_message.insufficient_rights'),
        type: 'error'
      }, 2000);
    });
    return null;
  }
  return <ItemPage video={video} appuser={appUserCreate} router={router} mutateVideo={mutateVideo} isLoadingVideo={isValidatingVideo} socialPosts={socialPosts?.socialPosts} mutateSocialPosts={mutateSocialPosts} isLoadingSocialPosts={isLoadingSocialPosts} isValidatingSocialPosts={isValidatingSocialPosts} />;
}
export default Video;