import { Variants } from 'framer-motion';
const dialogHelper: Variants = {
  init: route => ({
    position: 'fixed',
    top: '4.5rem',
    right: '1rem',
    zIndex: route.includes('video/new') ? 499 : 1301,
    opacity: 0,
    x: 200
  }),
  open: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      bounce: 0.25,
      duration: 0.4,
      damping: 15
    }
  },
  closed: {
    opacity: 0,
    x: 200,
    transition: {
      type: 'spring',
      bounce: 0.25,
      duration: 0.3,
      damping: 15
    }
  }
};
export default dialogHelper;