import React, { useCallback, useEffect, useState } from 'react';
import TooltipBox from '@components/bridge/bridge/TooltipBox';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@mui/material/Button';
import PhotoFilterOutlinedIcon from '@mui/icons-material/PhotoFilterOutlined';
import LoopIcon from '@mui/icons-material/Loop';
import _ from 'lodash';
import _axios from 'axios';
import * as Sentry from '@sentry/nextjs';
import { axios } from '@services/http/client';
import { HumanRatio } from '@components/office/front-office/organization/social/types/HumanRatio';
import { Daughter } from '@components/office/front-office/organization/social/types/Daughter';
import DaughterMenuActions from '@components/office/front-office/video/ratio-variation-panel/video-format-show/DaughterMenuActions';
import Networks from '@components/office/front-office/organization/social/Networks';
import VideoFile from '@components/bridge/bridge/VideoFile';
import ModalGenerate from './ModalGenerate';
import ModalThumbnail from './ModalThumbnail';
type Props = {
  title: string;
  video: any;
  appuser: any;
  workspace: any;
  format: HumanRatio;
  daughter: Daughter;
  helpMessage?: string;
  mutateVideo: () => void;
};
const VideoFormatShow = (props: Props) => {
  const {
    title,
    video,
    appuser,
    workspace,
    format,
    daughter,
    helpMessage,
    mutateVideo
  } = props;
  const [isOpenModalGenerate, setIsOpenModalGenerate] = useState<boolean>(false);
  const [isOpenModalThumbnail, setIsOpenModalThumbnail] = useState<boolean>(false);
  const [isRemoveDaughter, setIsRemoveDaughter] = useState<boolean>(false);
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
  const handleDownload = useCallback(() => {
    const isPictorial: boolean = Networks.getIsPictorial(video);
    const fileSrc: string = (isPictorial ? daughter?.thumbnail?.src : daughter?.file?.src) || '';
    const fileExtension = isPictorial ? 'jpg' : 'mp4';
    const fileName = _.get(video, 'name') || _.get(video, 'template.name', '');
    addFlash({
      id: 'download',
      type: 'info',
      message: tr('front.video.flash.download'),
      closable: false,
      autoClose: false
    });
    _axios({
      url: fileSrc,
      method: 'GET',
      responseType: 'blob' // important
    }).then(response => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${fileName}.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
      updateFlash('download', {
        type: 'success',
        render: tr('front.video.flash.downloaded'),
        closable: true,
        autoClose: true
      });
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }).catch(error => {
      dismissFlash();
      addFlash({
        type: 'warning',
        message: tr('front.flash.error')
      });
      Sentry.captureException(error);
    });
  }, []);
  const handleSaveThumbnail = useCallback(async (file: File | null, capturedAt: number | null) => {
    const underWhitelabel = !window.areWeUnderDanimHost();
    const body = {
      videoId: daughter.id,
      humanRatio: format,
      thumbnailTime: capturedAt || null,
      thumbnail: file || null
    };
    let formData = new FormData();
    body.videoId !== null && formData.append('videoId', body.videoId);
    body.humanRatio !== null && formData.append('humanRatio', body.humanRatio);
    body.thumbnailTime !== null && formData.append('patch[thumbnailTime]', ((body.thumbnailTime as unknown) as string));
    body.thumbnail !== null && formData.append('patch[thumbnail]', body.thumbnail);
    const options = {
      method: 'POST',
      url: gen(`write/video/change-social-post-settings`),
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      underWhitelabel
    };
    await axios(options, {
      then: () => {
        mutateVideo();
        addFlash({
          type: 'success',
          message: tr('front.item_page.core.share.modal_thumbnail.success', {}, 'front_office-video')
        });
      },
      catch: e => {
        addFlash({
          type: 'warning',
          message: tr('front.item_page.core.share.modal_thumbnail.error', {}, 'front_office-video')
        });
        console.log(e);
        setIsLoadingSave(false);
      },
      finally: () => {
        setIsOpenModalThumbnail(false);
        setIsLoadingSave(false);
      }
    });
  }, [daughter, mutateVideo]);
  const handleMoreAction = useCallback((value: string) => {
    if (!video) return;
    if (value === 'edit') {
      setIsOpenModalGenerate(true);
      setIsRemoveDaughter(true);
    }
    if (value === 'download') {
      handleDownload();
    }
    if (value === 'thumbnail') {
      setIsOpenModalThumbnail(true);
    }
  }, [video, daughter]);
  useEffect(() => {
    // Permet de re-générer le format si celui-ci n'est pas terminé, lorsque le validator est true on arrête la boucle et on refresh la requête vidéo
    const aggregateFormats = async () => {
      await waitForAggregate({
        id: daughter.id,
        domain: 'video',
        queryAggregateBody: 'progress { percentage }',
        maxTries: 10000,
        // Simulate an infinite loop
        secDelayBetweenTries: 3,
        // try every 3 seconds for 10000 tries = ~8 hours and 30 minutes
        validator: data => {
          return data.progress?.percentage === 100 ?? false;
        }
      });
      mutateVideo();
    };
    if (daughter !== null) {
      if (daughter.progress?.percentage && daughter.progress?.percentage < 100) {
        aggregateFormats();
      }
      if (daughter.progress?.percentage === null) {
        aggregateFormats();
      }
    }
  }, [daughter]);
  const isDaughterMenuActionsVisible = useCallback(() => {
    return daughter !== null && daughter.progress?.percentage === 100;
  }, [daughter]);
  const isVideoFileVisible = useCallback(() => {
    const isPictorial: boolean = Networks.getIsPictorial(video);
    const isUrlExist: boolean = Boolean(isPictorial ? daughter?.thumbnail?.src : daughter?.file?.src);
    return daughter !== null && daughter.progress?.percentage === 100 && isUrlExist;
  }, []);
  return <>
            {isOpenModalGenerate && <ModalGenerate video={!isRemoveDaughter || !daughter ? video : daughter} appuser={appuser} workspace={workspace} format={format} isOpen={isOpenModalGenerate} isRemoveDaughter={isRemoveDaughter} prefillDaughterId={daughter?.id} motherId={video?.id} onClose={() => setIsOpenModalGenerate(false)} mutateVideo={mutateVideo} onSave={() => {
      setIsOpenModalGenerate(false);
    }} />}
            {isOpenModalThumbnail && <ModalThumbnail videoUrl={daughter?.file?.src} thumbnail={daughter?.socialPostSettings?.thumbnail} thumbnailTime={daughter?.socialPostSettings?.thumbnailTime} isOpen={isOpenModalThumbnail} onClose={() => setIsOpenModalThumbnail(false)} onSave={handleSaveThumbnail} isLoadingSave={isLoadingSave} />}
            <div className='-item'>
                <div className='-title'>
                    <div className='-text'>{title}</div>
                    {helpMessage && <div className='-help'>
                            <TooltipBox caption={helpMessage} style={{
            bottom: 10,
            right: -150,
            transform: 'translateY(-50%)'
          }}>
                                <InfoOutlinedIcon width={16} />
                            </TooltipBox>
                        </div>}
                    {isDaughterMenuActionsVisible() && <DaughterMenuActions onAction={handleMoreAction} isPictorial={Networks.getIsPictorial(video)} />}
                </div>
                <div className='-actions'>
                    {isVideoFileVisible() && <VideoFile src={cors(daughter?.file?.src, 'src')} poster={cors(daughter?.thumbnail?.src, 'src')} controls thrifty={false} isPictorial={Networks.getIsPictorial(video)} />}
                    {daughter !== null && (daughter.progress === null || daughter.progress?.percentage === null || daughter.progress?.percentage < 100) && <div className='-in-progress'>
                                <TooltipBox caption={daughter.progress?.percentage === null ? '0%' : `${daughter.progress?.percentage.toFixed(2)}%`} style={{
            bottom: 10,
            left: 0,
            transform: 'translateY(-50%)'
          }}>
                                    <LoopIcon width={24} sx={{
              animation: 'spin 2s linear infinite',
              '@keyframes spin': {
                '0%': {
                  transform: 'rotate(360deg)'
                },
                '100%': {
                  transform: 'rotate(0deg)'
                }
              }
            }} />{' '}
                                    {tr(`front.snippet.formats.in_progress`, {}, 'front_office-video')}
                                </TooltipBox>
                            </div>}
                    {daughter === null && <Button variant='gradient' startIcon={<PhotoFilterOutlinedIcon />} fullWidth onClick={() => setIsOpenModalGenerate(true)}>
                            {tr(`front.snippet.formats.buttons.label`, {}, 'front_office-video')}
                        </Button>}
                </div>
            </div>
        </>;
};
export default VideoFormatShow;