import React from 'react';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import Networks from '@components/office/front-office/organization/social/Networks';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { SaveAltOutlined } from '@mui/icons-material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import SocialNetworkShareItems from '@components/office/front-office/video/SocialNetworkShareAccordion/SocialNetworkShareItems';
type Props = {
  video: any;
  socialPosts: any;
  mutateSocialPosts: any;
  isLoadingSocialPosts: any;
  isValidatingSocialPosts: any;
};
const ShareHistoryPanel = (props: Props) => {
  const {
    video,
    socialPosts,
    mutateSocialPosts,
    isLoadingSocialPosts,
    isValidatingSocialPosts
  } = props;
  return <div className='-share' id='share'>
            <h2 className='-title'>{tr(`front.item_page.core.share.title`, {}, 'front_office-video')}</h2>
            <div className='-list-type'>
                <div className='-type'>
                    <div className='-title'>
                        <div className='-icon'>
                            <TaskAltOutlinedIcon color='success' />
                        </div>
                        <div className='-text'>{tr(`front.item_page.core.share.type.published.title`, {}, 'front_office-video')}</div>
                    </div>
                    {socialPosts?.filter(p => p.state === 'DONE')?.length > 0 ? <div className='items'>
                            <SocialNetworkShareItems mutateSocialPosts={mutateSocialPosts} isLoadingSocialPosts={isLoadingSocialPosts} isValidatingSocialPosts={isValidatingSocialPosts} shares={socialPosts?.filter(p => p.state === 'DONE')?.map(p => {
            if (!p.networkSettings) return;
            return {
              videoId: video.id,
              videoShortId: video.shortId,
              videoHumanRatio: video.humanRatio,
              id: p.id,
              network: Networks.getConfiguration().find(n => n.title.toLowerCase() === p.networkSettings?.target?.toLowerCase()),
              formats: p.formats,
              description: p.description,
              state: p.state,
              urlPreview: p.link
            };
          }) ?? []} />
                        </div> : <div className='-text'>{tr(`front.item_page.core.share.type.published.empty`, {}, 'front_office-video')}</div>}
                </div>
                <div className='-type'>
                    <div className='-title'>
                        <div className='-icon'>
                            <AccessTimeOutlinedIcon color='info' />
                        </div>
                        <div className='-text'>{tr(`front.item_page.core.share.type.scheduled.title`, {}, 'front_office-video')}</div>
                    </div>
                    {socialPosts?.filter(p => p.state === 'PENDING' || p.state === 'PROCESSING')?.length > 0 ? <div className='items'>
                            <SocialNetworkShareItems mutateSocialPosts={mutateSocialPosts} isLoadingSocialPosts={isLoadingSocialPosts} isValidatingSocialPosts={isValidatingSocialPosts} shares={socialPosts?.filter(p => p.state === 'PENDING' || p.state === 'PROCESSING')?.map(p => {
            if (!p.networkSettings) return;
            return {
              videoId: video.id,
              videoShortId: video.shortId,
              videoHumanRatio: video.humanRatio,
              id: p.id,
              network: Networks.getConfiguration().find(n => n.title.toLowerCase() === p.networkSettings?.target?.toLowerCase()),
              formats: p.formats,
              description: p.description,
              scheduledAt: p.scheduledFor,
              postedAt: p.postedAt,
              state: p.state
            };
          }) ?? []} />
                        </div> : <div className='-text'>{tr(`front.item_page.core.share.type.scheduled.empty`, {}, 'front_office-video')}</div>}
                </div>
                <div className='-type'>
                    <div className='-title'>
                        <div className='-icon'>
                            <SaveAltOutlined color='warning' />
                        </div>
                        <div className='-text'>{tr(`front.item_page.core.share.type.draft.title`, {}, 'front_office-video')}</div>
                    </div>
                    {socialPosts?.filter(p => p.state === 'DRAFT')?.length > 0 ? <div className='items'>
                            <SocialNetworkShareItems mutateSocialPosts={mutateSocialPosts} isLoadingSocialPosts={isLoadingSocialPosts} isValidatingSocialPosts={isValidatingSocialPosts} shares={socialPosts?.filter(p => p.state === 'DRAFT')?.map(p => {
            if (!p.networkSettings) return;
            return {
              videoId: video.id,
              videoShortId: video.shortId,
              videoHumanRatio: video.humanRatio,
              id: p.id,
              network: Networks.getConfiguration().find(n => n.title.toLowerCase() === p.networkSettings?.target?.toLowerCase()),
              formats: p.formats,
              description: p.description,
              scheduledAt: p.scheduledFor,
              postedAt: p.postedAt,
              state: p.state
            };
          }) ?? []} />
                        </div> : <div className='-text'>{tr(`front.item_page.core.share.type.draft.empty`, {}, 'front_office-video')}</div>}
                </div>
                <div className='-type'>
                    <div className='-title'>
                        <div className='-icon'>
                            <WarningAmberOutlinedIcon color='error' />
                        </div>
                        <div className='-text'>{tr(`front.item_page.core.share.type.error.title`, {}, 'front_office-video')}</div>
                    </div>
                    {socialPosts?.filter(p => p.state === 'ERROR')?.length > 0 ? <div className='items'>
                            <SocialNetworkShareItems mutateSocialPosts={mutateSocialPosts} isLoadingSocialPosts={isLoadingSocialPosts} isValidatingSocialPosts={isValidatingSocialPosts} shares={socialPosts?.filter(p => p.state === 'ERROR')?.map(p => {
            if (!p.networkSettings) return;
            return {
              videoId: video.id,
              videoShortId: video.shortId,
              videoHumanRatio: video.humanRatio,
              id: p.id,
              network: Networks.getConfiguration().find(n => n.title.toLowerCase() === p.networkSettings?.target?.toLowerCase()),
              formats: p.formats,
              description: p.description,
              scheduledAt: p.scheduledFor,
              postedAt: p.postedAt,
              state: p.state,
              error: p.error
            };
          }) ?? []} />
                        </div> : <div className='-text'>{tr(`front.item_page.core.share.type.error.empty`, {}, 'front_office-video')}</div>}
                </div>
            </div>
        </div>;
};
export default ShareHistoryPanel;