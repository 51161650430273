import React from 'react';
import { Network } from '@components/office/front-office/organization/social/types/Network';
import { faInstagram as InstagramIcon } from '@fortawesome/free-brands-svg-icons';
import { HumanRatioEnum } from '@components/office/front-office/organization/social/enums/HumanRatioEnum';
const InstagramConfiguration = (): Network => {
  return {
    name: 'instagram',
    title: 'Instagram',
    subtitleFieldName: 'actualBusinessAccount',
    icon: InstagramIcon,
    color: '#E1306C',
    rules: {
      video: {
        types: {
          post: false,
          reel: {
            title: false,
            description: {
              required: false
            },
            availableHumanRatio: [HumanRatioEnum.SQUARE, HumanRatioEnum.LANDSCAPE, HumanRatioEnum.PORTRAIT]
          },
          story: {
            title: false,
            description: {
              required: false
            },
            availableHumanRatio: [HumanRatioEnum.SQUARE, HumanRatioEnum.LANDSCAPE, HumanRatioEnum.PORTRAIT]
          },
          short: false
        },
        defaultType: 'reel',
        durations: {
          post: {
            min: 0,
            max: 0
          },
          reel: {
            min: 0,
            max: 90
          },
          story: {
            min: 0,
            max: 60
          },
          short: {
            min: 0,
            max: 0
          }
        }
      },
      picture: {
        types: {
          post: {
            title: false,
            description: {
              required: false
            },
            availableHumanRatio: [HumanRatioEnum.SQUARE, HumanRatioEnum.LANDSCAPE, HumanRatioEnum.PORTRAIT]
          },
          reel: false,
          story: {
            title: false,
            description: {
              required: false
            },
            availableHumanRatio: [HumanRatioEnum.SQUARE, HumanRatioEnum.LANDSCAPE, HumanRatioEnum.PORTRAIT]
          },
          short: false
        },
        defaultType: 'story'
      }
    },
    enabled: true,
    editorOptions: {
      toolbar: []
    }
  };
};
export default InstagramConfiguration;